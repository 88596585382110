.app {
  min-height: 100vh;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  display: grid;
  grid-template-rows: 5.625rem 1fr 3.75rem;
  grid-template-areas:  "header"
                        "main"
                        "footer";
                        
  &::after {
    position: absolute;
    top: 57%;
    right: 10px;
    transform: rotate(-90deg);
    opacity: 1;
    z-index: -1;
    width: 400px;
    height: 10px;
    text-align: center;
    color: rgba(226, 226, 226, 0.1);;
    font-size: 100px;
    font-weight: bold;
    content: 'FunRoute#';
  }

  
  &__header {
    grid-area: header;
    position: fixed;
    top:0;
    z-index: 1000;
    width: 100%;
  }


  &__main {
    grid-area: main;
  }


  &__footer {
    grid-area: footer;
    padding: 1.25rem;
  }
}


.main {
  justify-content: start;
  width: 100%;
  max-width: 1440px;
  display: grid;
  justify-content: center;
  box-sizing: border-box;
  column-gap: 20px;
  margin: 0 auto;
  padding-right: $side-gap;
  padding-left: $side-gap;
  row-gap: 20px;
  grid-template-rows: 15rem  73px  1fr minmax(25rem, auto);
  grid-template-columns: minmax(290px, 450px);
  grid-template-areas:  "map"
                        "new-point"
                        "type-button"
                        "route";
  
  @media (min-width: $screen-md) {
    grid-template-rows: 73px 1fr 56px;
    grid-template-columns: minmax(20rem, 1fr) 20rem;
    grid-template-areas:  "map new-point"
                          "map route"
                          "map type-button";
  }

  @media (min-width: $screen-ld) {
    grid-template-rows: 73px 1fr 56px;
    grid-template-columns: minmax(20rem, 1fr) minmax(20rem, 28.125rem);
    grid-template-areas:  "map new-point"
                          "map route"
                          "map type-button";
  }

  @media (min-width: $screen-max) {
    padding-left: 0;
    padding-right: 0;
  }

  
  &__map {
    grid-area: map;
    width: 100%;
  }
  
  &__new-point {
    grid-area: new-point;
  }
  
  &__route {
    grid-area: route;
  }
  
  &__type-button {
    grid-area: type-button;
  }
}
