@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Roboto:700&display=swap&subset=cyrillic');



$color-main: #16A085;
$color-bg-body: rgba(44, 62, 80, 0.95);
$color-secondary: #798995;
$color-container-bg: #2C3E50;
$color-item-bg: #34495E;
$gray-lightnes: #e9e9e9;

$screen-max:  1450px;
$screen-ld:   1000px;
$screen-md:   700px;
$screen-sd:   400px;

$side-gap: 0.9375rem;

$font-family: 'Montserrat', Arial, sans-serif;
$font-roboto: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;


body, h1, h2, h3, p {
  margin: 0;
  padding: 0;
}


body {
  margin: 0;
  background: $color-bg-body;
  width: 100%;
  font-family: $font-family;
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;


  &::before {
    position: absolute;
    top: 0;
    right: 50px;
    border-right: 5px dashed rgba(47, 62, 76, 0.7);
    border-left: 5px dashed rgba(47, 62, 76, 0.7);
    width: 10px;
    height: 100%;
    content: '';
  }
}


h3 {
  line-height: 0.75rem;
  font-family: $font-family;
  font-size: 0.75rem;
  font-weight: bold;
  font-style: normal;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
