.header {
  display: grid;
  background-color: $color-bg-body;
  width: 100%;
  height: 4.0625rem;
  grid-template-columns: minmax($side-gap, 1fr) minmax(18.125rem, 28.125rem) minmax($side-gap, 1fr);
  grid-template-areas:  "  .   up-line    .  "
                        " line   line   line ";

  @media (min-width:  $screen-md) {
    grid-template-columns: $side-gap minmax(20rem, 1fr) $side-gap;
  }

  @media (min-width: $screen-max) {
    grid-template-columns: 1fr 90rem 1fr;
  }


  &__up-line {
    grid-area: up-line;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
  }


  &__logo {
    transform: translateY(14px);
    transition: all 0.3s linear;
    z-index: 10000;
    cursor: pointer;
    width: 100%;
    max-width: 12.5rem;
    overflow: visible;
    text-decoration: none;
    text-shadow: 0px 0.25rem 0.3125rem rgba(0, 0, 0, 0.2);
    line-height: 3rem;
    color: $color-secondary;
    font-family: $font-roboto;
    font-size: 2.25rem;
    font-weight: bold;
    user-select: none;

    &:hover {
      opacity: 1;
      color: $color-main;
    }

    &:active {
      opacity: 0.2;
      color: $color-secondary;
    }
  }


  &__menu-item {
    transform: translateY(24px); 
    transition: all 0.3s linear;
    opacity: 0.3;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    height: 1.875rem;
    vertical-align: middle;
    text-decoration: none;
    color: $color-secondary;
    font-family: $font-roboto;
    font-size: 1.125rem;
    font-weight: bold;

    &:hover {
      opacity: 1;
      color: $color-main;

    }

    &:active {
      opacity: 0.2;
      color: $color-secondary;
    }

    &--active {
      display: none;
    }
  }


  &__decor-line {
    grid-area: line;
    box-shadow: 0rem 0.25rem 0.3125rem rgba(0, 0, 0, 0.2), 0px 0.1875rem 0.875rem rgba(0, 0, 0, 0.12), 0rem 0.5rem 0.625rem rgba(0, 0, 0, 0.14);
    background-color: $color-main;
    width: 100%;
    height: 0.75rem;
  }
}
