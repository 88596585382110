.footer {
  text-align: center;
  user-select: none;


  .footer__link {
    color: $gray-lightnes;
    text-decoration: none;
    transition: all 0.3s linear;

    &:hover {
      color: $color-main;
    }
  }
}
